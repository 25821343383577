import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="mint-process"
export default class extends Controller {
    static targets = ["searchForm", "sort"]
    filter() {
        console.log("aaaaa")
        const element = this.searchFormTarget
        element.submit();
        return false;
    }
}