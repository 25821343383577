import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="burger-menu"
export default class extends Controller {
    connect() {
        const filterMenu = document.querySelector('#navbar-item-filter');
        const filter = document.querySelector('#navbar-filter');
        if (filterMenu) {
            filterMenu.addEventListener('click', function () {
                filterMenu.classList.toggle('is-active');
                filter.classList.toggle('is-active');
            });
        }
    }
}
