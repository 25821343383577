import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    connect() {
        // console.log('index controller');
        this.element.firstElementChild.playbackRate = 1.25;
    }

}
