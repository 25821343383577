import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="wine"
export default class extends Controller {
    static targets = ["searchForm", "sort"]

    filter() {

        const element = this.searchFormTarget
        element.submit();
        return false;
    }

    // deselectFilter() {
    //     console.log("deselect");
    //     return false;
    // }

}
