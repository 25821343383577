import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="menu"
export default class extends Controller {
    connect() {
        // console.log("connected");
        const back = document.querySelector('.stick.back');
        const burger = document.querySelector('.navbar-burger');
        const menu = document.querySelector('.navbar-menu');
        burger.addEventListener('click', function () {
            // console.log('burger click');
            burger.classList.toggle('is-active');
            menu.classList.toggle('is-active');
            if (back) {
                back.classList.toggle('is-hidden-touch');
            }
        });
    }


}
