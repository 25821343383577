import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="burger-menu"
export default class extends Controller {
    connect() {

        const back = document.querySelector('.stick.back');
        const burger = document.querySelector('#navbar-burger');
        const menu = document.querySelector('#navMenu');
        burger.addEventListener('click', function () {
            burger.classList.toggle('is-active');
            menu.classList.toggle('is-active');
            if (back) {
                back.classList.toggle('is-hidden-touch');
            }
        });

    }
}
