import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="filter"
export default class extends Controller {

    static values = {filterId: String}

    deselectFilter() {
        const el = document.getElementById(this.element.dataset.filterId);
        if (el) {
            el.click();
        }
        return false;
    }

}
