import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="slideshow"
export default class extends Controller {
    static targets = ["checkbox"]

    step = 0;

    connect() {
        setInterval(() => this.slide(), 4000);
    }

    slide() {
        const boxes = this.checkboxTargets;
        this.step++;

        if (this.step >= boxes.length) {
            this.step = 0;
        }

        const box = boxes[this.step];
        box.checked = true;
    }

    click(event) {
        this.step = event.target.innerHTML - 1;
    }
}
