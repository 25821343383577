import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="commission"
export default class extends Controller {
    static targets = ["commission", "input", "locale", "submit", "total", "value", "price"]
    numberFormat;

    connect() {
        const locale = this.localeTarget.value;
        this.numberFormat = new Intl.NumberFormat(locale, {style: 'currency', currency: 'EUR'});
        this.commissionTarget.innerHTML = this.numberFormat.format(0);
        this.totalTarget.innerHTML = this.numberFormat.format(0);
    }

    updateCommission() {
        const input = this.inputTarget;
        const commission = this.commissionTarget;
        const submitButton = this.submitTarget;
        const priceInput = this.priceTarget;
        const total = this.totalTarget;

        input.value = input.value.replace(",", ".");
        
        const price = Number.parseFloat(input.value) | 0;

        if (price && price > 0) {
            submitButton.removeAttribute("disabled")
        } else {
            submitButton.setAttribute("disabled", true)
        }

        commission.innerHTML = this.numberFormat.format(price * 0.1);
        total.innerHTML = this.numberFormat.format(price * 1.1);
        priceInput.value = price * 1.1;
    }

    updateInput() {
        const input = this.inputTarget;
        const price = Number.parseFloat(input.value) | 0;
        input.value = this.numberFormat.format(price);
    }
}
