import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["singleBottle", "count"]

    toggleFields() {
        const elements = this.singleBottleTargets;
        const count = this.countTarget.value;

        for (const el of elements) {
            if (count == 1) {
                el.disabled = false;
            } else {
                el.disabled = true;
            }
        }
    }

}
